import { Component, React } from 'react'
import { Nav, Navbar, NavbarBrand, NavDropdown } from 'react-bootstrap'
import { Link, Routes, Route } from 'react-router-dom';
import { FAQ } from './faq';
import { Keybinds } from './Keybinds';


import '../App.css';
import 'bootstrap/dist/css/bootstrap.css'
import discordlogo from '../media/imgs/discord-logo-white.c4634138.svg';
import twitterlogo from '../media/imgs/twitter-logo-white.23e6bb64.svg';
import twitchlogo from '../media/imgs/TwitchGlitchWhite.svg';
import tiktoklogo from '../media/imgs/TikTok-Social-Icon-Mono-White.svg';


export default class Navbarelement extends Component {
    render() {
        return (
          <Navbar variant="dark" fixed="top" expand="sm"  collapseOnSelect>
  
            <Navbar.Toggle className="coloring" />
            <Navbar.Collapse>
              <Nav>
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <NavDropdown title="Rules">
                  <NavDropdown.Item href="http://wiki.calicocountyrp.com/index.php/Calico_County_RP_Server_Rules">Server Rules</NavDropdown.Item>
                  <NavDropdown.Item href="http://wiki.calicocountyrp.com/index.php/Calico_County_RP_County_Laws">County Laws</NavDropdown.Item>
                  <NavDropdown.Item href="http://wiki.calicocountyrp.com/index.php/Calico_County_RP_Criminal_Guidelines">Criminal Guidelines</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={"/faq"}>FAQ</Nav.Link>
                <Nav.Link href="http://wiki.calicocountyrp.com/index.php/Main_Page">Wiki</Nav.Link>
{/*                 <Nav.Link href="http://wiki.calicocountyrp.com/index.php/Getting_Started">Getting Started</Nav.Link>
 */}                <NavDropdown title="Applications">
                  <NavDropdown.Item href="https://bit.ly/CalicoRPLawApp">Law Application</NavDropdown.Item>
                  <NavDropdown.Item href="https://bit.ly/3xDZvSy">Medical Application</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="More">
                  <NavDropdown.Item as={Link} to={"/Keybinds"}>Keybinds</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#reports/bug">Bug Report</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/support">Get Support</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            <NavbarBrand>
              <a href="https://discord.gg/calicocounty" target="_blank" rel="noreferrer"> <img src={discordlogo} width="30px" height="30px" alt="Discordlogo" />{' '} </a>
              <a href="https://twitter.com/calicocountyrp" target="_blank" rel="noreferrer"> <img src={twitterlogo} width="30px" height="30px" alt="Twitterlogo" />{' '} </a>
              <a href="https://www.tiktok.com/@calicocountyrp" target="_blank" rel="noreferrer"> <img src={tiktoklogo} width="30px" height="30px" alt="Tiktok Logo" />{' '} </a>
              <a href="https://twitch.tv/calicocountyrp" target="_blank" rel="noreferrer"> <img src={twitchlogo} width="30px" height="30px" alt="Twitchlogo" />{' '} </a>
            </NavbarBrand>
            <Routes>
              <Route path="/faq" element={<FAQ/>}/>
              <Route path="/keybinds" element={<Keybinds />}/>
          </Routes>
          </Navbar> 
        )
    }
}
import React from 'react';
import backgroundVideo from '../media/video/new.mp4';
import Accordion from 'react-bootstrap/Accordion';
import Navbarelement from './navbarelement';


import 'bootstrap/dist/css/bootstrap.css'
import '../accordion.css'


export const FAQ = () => {
  
  return (
    <div className="content">   
    <Navbarelement></Navbarelement>
      <div class="background-wrapper">
        <video id='bgvideo' width="100%" height="auto" autoPlay={true} muted loop>
          <source src={backgroundVideo} type='video/mp4' /> {/*If for whatever reason this breaks again run this and convert the video: ffmpeg -i pathtovideo pathtooutput.mp4*/}
        </video>
      </div>
      
      <br />

      <div className = "faq">
        <h1>Frequently Asked Questions</h1>
        <br />
        <br />

        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What VOIP Service does Calico Use?</Accordion.Header>
            <Accordion.Body>
              We use PMA-Voice for communications in county. No need for Teamspeak or another application to talk to others in county.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>I'm in Discord but can't connect</Accordion.Header>
            <Accordion.Body>
              If you are in the <a href="discord.gg/calicocounty">Discord</a> but can not connect to county, please check the following. <br />
                - Make sure you have the @Townfolk role <br />
                - Restart Discord, make sure it isn't runnining in Administrator Mode <br />
                - Make sure Steam is running (even if you don't own RDR2 or RDO through steam) <br />
                - If you are still having issues, please create a ticket and staff will assist you.
            </Accordion.Body>
          </Accordion.Item>
{/*           <Accordion.Item eventKey="2">
            <Accordion.Header>How do I Join Calico County?</Accordion.Header>
            <Accordion.Body>
              To Join Calico County please read our Taking your First Steps in Calico County guide
            </Accordion.Body>
          </Accordion.Item> */}
          {/* <Accordion.Item eventKey="3">
            <Accordion.Header>How long does it take for my Whitelist to be approved?</Accordion.Header>
            <Accordion.Body>
              Our staff does their best to respond to whitelist applications in 48 hours. Staff is busy outside of county as well, so be paitent, and we will approve it asap!
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey="2">
            <Accordion.Header>Is this server available on Xbox or PS4?</Accordion.Header>
            <Accordion.Body>
              No. RedM Does not work with Xbox or PS4. 
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Is there controller support?</Accordion.Header>
            <Accordion.Body>
              RedM and basegame (RDO/RdR2) has controller support. Although you can use it in county, we (calico) are not supporting it at this time
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How do you download RedM?</Accordion.Header>
            <Accordion.Body>
              You can download RedM from https://redm.net
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}
import React, { Component } from 'react'

import '../App.css';
import 'bootstrap/dist/css/bootstrap.css'

export default class About extends Component {
  render() {
    return (
      <div className='section'>
        <div className='about'>
          <br />
          <br />
          <br />
          {/* <h1>About Calico County</h1> */}
{/*           <p>We run our community primarily via Discord. All annoucements, updates, developer previews and general communcation can be found there. You must be in the Discord in order to access the RedM Server.</p>
 */}        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
/* import backgroundVideo from '../media/video/strawberry.mp4';*/
import backgroundVideo from '../media/video/Calico_Showcase.mp4';
import bgstatic from '../media/imgs/bgstatic.jpg';
import welcometocc from '../media/imgs/InkRoll.png';
import { BrowserRouter } from 'react-router-dom';
import About from './about';
import Footer from './footer';
import Socials from './socials';

import Navbarelement from './navbarelement'

import '../App.css';
import 'bootstrap/dist/css/bootstrap.css'

export default class Home extends Component {
  render() {
    return (
        <BrowserRouter>
        <div className="App">
            <Navbarelement></Navbarelement>
  
          <div className="content">   
            <div class="background-wrapper">
              <video autoPlay={true} muted loop>
                <source src={backgroundVideo} type='video/mp4' /> {/*If for whatever reason this breaks again run this and convert the video: ffmpeg -i pathtovideo pathtooutput.mp4*/}
              </video>
            </div>
            <div class="landing">
              <img class="welcomeText" src={welcometocc} alt="Welcome to Calico County"></img>
            </div>
            <br />
            <div class="infosection">
{/*               <About></About>
              <Socials></Socials>
              <Footer></Footer> */}
            </div>
          </div>
        </div>
        </BrowserRouter>
    )
  }
}

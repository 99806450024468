import React, { Component } from 'react'

import '../App.css';
import 'bootstrap/dist/css/bootstrap.css'

export default class Socials extends Component {
    render() {
        return (
            <div className='section'>
                <div className='socials'>
                    <div className = 'discord'>
                        <h1>Join The Discord</h1>
                        <iframe src="https://discord.com/widget?id=975491656142954496&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                    </div>
                    <div className = 'twitter'>
                        <h1>Follow on Twitter</h1>
                        <a class="twitter-timeline" data-width="350" data-height="500" data-theme="dark" href="https://twitter.com/CalicoCountyRP?ref_src=twsrc%5Etfw">Tweets by CalicoCountyRP</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                </div>
            </div>
        )
    }
}
